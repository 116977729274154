import React from 'react';
import classNames from 'classnames';
import Icon from 'components/ui-components-v2/Icon';
import '../styles/main.scss';

/**
 * Render the different type icons.
 * @param {*} type
 * @returns
 */
export const renderFeedIconType = (className, type) => {
    switch (type) {
        case 'googleShopping':
            return (
                <Icon className={className} fontSize="inherit">
                    shopping_basket
                </Icon>
            );
        case 'spreadsheet':
        case 'xlsx':
            return (
                <Icon className={className} fontSize="inherit">
                    dataset
                </Icon>
            );
        case 'rss':
            return (
                <Icon className={className} fontSize="inherit">
                    rss_feed
                </Icon>
            );
        case 'convertedFeed':
            return (
                <Icon className={className} fontSize="inherit">
                    sync_alt
                </Icon>
            );
        case 'xml':
            return (
                <Icon className={className} fontSize="inherit">
                    code
                </Icon>
            );
        case 'json':
            return (
                <Icon className={className} fontSize="inherit">
                    data_object
                </Icon>
            );
        case 'csv':
            return (
                <Icon className={className} fontSize="inherit">
                    csv
                </Icon>
            );
        case 'custom':
            return (
                <Icon className={className} fontSize="inherit">
                    person
                </Icon>
            );
        default:
            return (
                <Icon className={className} fontSize="inherit">
                    stop_circle
                </Icon>
            );
    }
};

/**
 * Dsiplay the right icon for a feed type.
 * @param {*} param0
 * @returns
 */
const FeedTypeIcon = ({ type, className }) => {
    return <div className={classNames('feed-management-feed-type-icon', className)}>{renderFeedIconType('', type)}</div>;
};

export default FeedTypeIcon;
